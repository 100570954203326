<template>
    <div class="hello">
        About
    </div>
</template>

<script>
export default {
    name: 'About_tinder',
}
</script>
<style scoped>

</style>