<template>
    <router-view></router-view>
</template>

<script>
import "./assets/css/style.css"

export default {
    name: 'App',
    components: {

    }
}
</script>