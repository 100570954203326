<template>
    <div id="app">
        <Tinder class="tinder_wrapper" ref="tinder" key-name="id" :queue.sync="queue" :offset-y="0" @submit="onSubmit">
            <template slot-scope="scope">
                <div class="pic" :style="{ 'background-image': `url(https://cn.bing.com//th?id=OHR.${scope.data.id}_UHD.jpg&pid=hp&w=720&h=1280&rs=1&c=4&r=0)` }">
                    <div class="info_user_pic">
                        <p class="p_action">Có hoạt động gần đây</p>
                        <h2 class="name_pic">Rosie 22 <span @click="showModal"><svg focusable="false" aria-hidden="true" role="presentation" viewBox="0 0 24 24" width="24px" height="24px" class="Expand"><path fill="#fff" d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"></path></svg></span></h2>
                        <p class="add_pic"><img src="../assets/images/add.svg" alt=""> Sống tại Hà Nội</p>
                        <p class="location_pic"><img src="../assets/images/location.svg" alt=""> Cách xa 5km</p>
                    </div>
                </div>
                <div id="modal-example" class="root-modal-info">
                    <div class="overlay" v-if="modal" @click="modal = false"></div>
                    <div class="modal modal_info" v-if="modal">
                        <div class="wp-modal-info">
                            <div class="modalpp">
                                <div class="wp_slider_info">
                                    <carousel ref="carousel" :perPage="1" :navigationEnabled="true" :paginationEnabled="true" :loop="false" :autoplay="false"  :navigateTo.sync="currentSlide">
                                        <slide>
                                            <img src="../assets/images/1.png" alt="Slide 1">
                                        </slide>
                                        <slide>
                                            <img src="../assets/images/2.png" alt="Slide 2">
                                        </slide>
                                        <slide>
                                            <img src="../assets/images/3.png" alt="Slide 3">
                                        </slide>
                                        <slide>
                                            <img src="../assets/images/4.png" alt="Slide 4">
                                        </slide>
                                        <slide>
                                            <img src="../assets/images/5.png" alt="Slide 5">
                                        </slide>
                                    </carousel>
                                    <button class="close-modal" @click="hideModal"><img src="../assets/images/close.png" alt=""></button>
                                </div>
                                <div class="info_user_idol">
                                    <div class="info_detail">
                                        <div class="pd_lr_info">
                                            <h2 class="name_detail">Rosie 22</h2>
                                            <p class="detail_us"><img src="../assets/images/cm.svg" alt=""> 158 cm</p>
                                            <p class="detail_us"><img src="../assets/images/add.svg" alt=""> Sống tại Hà Nội</p>
                                            <p class="detail_us"><img src="../assets/images/location.svg" alt=""> Cách xa 5km</p>
                                        </div>
                                    </div>
                                    <div class="info_detail">
                                        <div class="pd_lr_info">
                                            <h3 class="pc_detail">Thông tin thêm về tôi</h3>
                                            <ul>
                                                <li>Thích gặp mặt trực tiếp</li>
                                                <li>Những món quà</li>
                                                <li>Những hành động thực tế</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="info_detail">
                                        <div class="pd_lr_info">
                                            <h3 class="pc_detail">Phong cách sống</h3>
                                            <ul>
                                                <li>Không hút thút</li>
                                                <li>Dậy sớm</li>
                                                <li>Nuôi thú cưng</li>
                                                <li>Đi dạo</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="info_detail">
                                        <div class="pd_lr_info">
                                            <h3 class="st_detail">Sở thích</h3>
                                            <ul>
                                                <li>Du lịch</li>
                                                <li>Nấu ăn</li>
                                                <li>Cung Thiên Bình</li>
                                                <li>Thể thao</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <span class="rewind-pointer" slot="rewind">Quay lại</span>
            <span class="nope-pointer" slot="nope">Không</span>
            <span class="super-pointer" slot="super">Siêu Thích</span>
            <span class="like-pointer" slot="like">Thích</span>
        </Tinder>
        <div class="btns" v-if="show == 1">
            <img src="../assets/images/rewind.png" @click="decide('rewind')">
            <img src="../assets/images/nope.png" @click="decide('nope')">
            <img src="../assets/images/super-like.png" @click="decide('super')">
            <img src="../assets/images/like.png" @click="decide('like')">
        </div>
        <div class="btns_arrow">
            <div class="wp_btn_showhide_arrow">
                <span class="hidebtn_arrow" v-if="checkshowArrow == 1" @click="hideArrow">Ẩn</span>
                <span class="showbtn_arrow" v-else @click="showArrow">Hiển thị</span>
            </div>
            <div class="btn_arrow_detail" v-if="checkshowArrow == 1">
                <span><img src="../assets/images/arrowleft.png" alt=""> Không</span>
                <span><img src="../assets/images/arrowright.png" alt=""> Thích</span>
                <span><img src="../assets/images/arrowup.png" alt=""> Mở thông tin</span>
                <span><img src="../assets/images/arrowdown.png" alt=""> Đóng thông tin</span>
                <span><img src="../assets/images/arrowenter.png" alt=""> Siêu thích</span>
                <span><img src="../assets/images/space.png" alt=""> Ảnh tiếp theo</span>
            </div>
        </div>
    </div>
</template>

<script>
import Tinder from "vue-tinder";
import source from "@/bing";

import { Carousel, Slide } from 'vue-carousel';

export default {
    name: 'Home_tinder',
    components: { 
        Tinder,
        Carousel,
        Slide
    },
    data: () => ({
        queue: [],
        offset: 0,
        history: [],
        modal: false,
        show: 1,
        checkshowArrow: 1,
        currentSlide: 0,
        totalSlides: 5,
        // source: [
        //     "midmoon_ZH-CN4973736313",
        //     "MilkyWayCanyonlands_ZH-CN2363274510",
        //     "DaintreeRiver_ZH-CN2284362798",
        //     "TsavoGerenuk_ZH-CN2231549718",
        //     "ArroyoGrande_ZH-CN2178202888",
        //     "SouthernYellow_ZH-CN2055825919",
        // ]
    }),
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keydown', this.handleKeySpace);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keydown', this.handleKeySpace);
    },
    created() {
        this.mock();
    },
    methods: {
        mock(count = 5, append = true) {
            const list = [];
            for (let i = 0; i < count; i++) {
                list.push({ id: source[this.offset] });
                this.offset++;
            }
            if (append) {
                this.queue = this.queue.concat(list);
            } else {
                this.queue.unshift(...list);
            }
        },
        onSubmit({ item }) {
            if (this.queue.length < 3) {
                this.mock();
            }
            this.history.push(item);
        },
        async decide(choice) {
            if (choice === "rewind") {
                if (this.history.length) {
                    this.$refs.tinder.rewind([this.history.pop()]);
                }
            } else {
                this.$refs.tinder.decide(choice);
            }
        },
        handleKeyDown(event) {
            if (event.key === "ArrowLeft") {
                this.swipeLeft();
            } else if (event.key === "ArrowRight") {
                this.swipeRight();
            } else if (event.key === "ArrowUp") {
                this.swipeUp();
            } else if (event.key === "ArrowDown") {
                this.swipeDown();
            } else if (event.key === "Enter") {
                this.swipeEnter();
            }
        },
        swipeLeft() {
            this.decide('nope')
            this.modal = false
            this.show = 1
        },
        swipeRight() {
            this.decide('like')
            this.modal = false
            this.show = 1
        },
        swipeUp() {
            this.modal = true
            this.show = 0
            this.currentSlide = 0
        },
        swipeDown() {
            this.modal = false
            this.show = 1
        },
        swipeEnter() {
            this.decide('super')
            this.modal = false
            this.show = 1
        },
        showModal () {
            this.modal = true,
            this.show = 0
            this.currentSlide = 0
        },
        hideModal () {
            this.modal = false,
            this.show = 1
        },
        hideArrow () {
            this.checkshowArrow = 0
        },
        showArrow () {
            this.checkshowArrow = 1
        },
        handleKeySpace (event) {
            if (event.key === " ") {
                this.currentSlide = (this.currentSlide + 1) % this.totalSlides;
            }
        }
    }
}
</script>
<style scoped>
</style>