import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Home_tinder from '@/components/Home.vue';
import About_tinder from '@/components/About.vue';

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home_tinder,
        },
        {
            path: '/about',
            name: 'About',
            component: About_tinder
        },
    ],
});